import React from 'react';

class BottomSliderTopNavi extends React.Component {

    constructor(props){
		super(props);
	}


    render() {
        return (
            <React.Fragment>
                <div className={"switchButtons " + (this.props.selfRenderReady ? 'show':'hide')}>
                    <button className={"collection smallButton hover " + (this.props.bottomSlider === 'download' ? 'styleActive' : 'styleInactive')}  onClick={()=>this.props.onSliderPageChange("download")}>Existing Image</button>
                    <button id="selfRenderButton" className={"collection smallButton hover " + (this.props.bottomSlider === 'selfRender' ? 'styleActive' : 'styleInactive')} onClick={()=>this.props.onSliderPageChange("selfRender")}>Self Render</button>
                    <button id="selfRenderButtonForMobile" className="collection smallButton styleButtonNotClickable" disabled>Self Render(Desktop Only)</button>
                </div>

                <div className={"switchButtons " + (this.props.selfRenderReady ? 'hide':'show')}>
                    <button className={"collection smallButton hover " + (this.props.bottomSlider === 'download' ? 'styleActive' : 'styleInactive')}  onClick={()=>this.props.onSliderPageChange("download")}>Existing Image</button>
                    <button className="collection smallButton styleButtonNotClickable" disabled>Self Render</button>
                    <button className="collection smallButton styleButtonNotClickable" disabled style={{visibility:"hidden"}}>Self Render(Desktop Only)</button> {/* this button is never used, just here to make sure the component aligns well with the top group*/}
                </div>
            </React.Fragment>
        );
    }
}

export default BottomSliderTopNavi;