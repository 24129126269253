import React, { Component } from 'react';

class Navi extends Component {
  render() {
    return (
      	<div id="navi">
        </div>
    );
  }
}

export default Navi;
