import React, { Component } from 'react';
import posed from 'react-pose';

var Spinner = require('react-spinkit');

const ResultsDiv = posed.div({
  pressable: true,
  hoverable:true,
  init: {opacity:1, scale:1},
  press: {opacity:0.7},
  hover: { scale:1.1, opacity:0.5}
});

const GeneralButton = posed.div({
  pressable: true,
  hoverable:true,
  init: {opacity:1, scale:1},
  press: {opacity:1, scale:1.2},
  hover: {opacity:0.5, scale:1.1}
});



class Results extends Component {

  constructor(props){
		super(props);
		
		this.state = {
			showSpinner: ""
		}

	} //for slider

  showElementSpinner = (element) => {

    console.log("sdf");
    // show spinner first

    this.setState({showSpinner : element});
    this.props.onShowDownload(element);
  }

  componentDidMount = () => {
    console.log("aaaa");
    if(this.state.showSpinner !== ''){
      this.setState({showSpinner : ''});
    }
  
  }
  

  render() {
   
    const resultList = this.props.elements.map(element => (
        <ResultsDiv key={element.imgName} className="element hover" onClick={() => this.showElementSpinner(element.imgName)}>
          <div className={'objectDarkerOverLay '+ (this.state.showSpinner === element.imgName ? 'show' : 'hide')}><Spinner name='double-bounce' className = "objectSpinner" color="#82B97B" /></div>
          <img src={'https://storage.googleapis.com/isometriclove.appspot.com/resized/thumb_'+element.imgName} />
        </ResultsDiv>
    ))

    const displayElementNumber = this.props.elements.length;


    return (
      <React.Fragment>    
        <div className='results'>
          <div id="counters">
            <div id="totalCount">
             <button className={"collection hover "  + (this.props.collection === "all" ? 'styleActive' : 'styleInactive')} onClick={() => this.props.toggleCollection('all')}><div className= "styleIconSpace"></div><div className="styleLabel">All Collections</div></button>
             <button className={"collection hover "  + (this.props.collection === "voxel" ? 'styleActive' : 'styleInactive')} onClick={() => this.props.toggleCollection('voxel')}><img src="assets/voxelStyle.png" className="styleIcon"/><div className="styleLabel">Voxel</div></button>
             <button className={"collection hover "  + (this.props.collection === "smooth" ? 'styleActive' : 'styleInactive')} onClick={() => this.props.toggleCollection('smooth')}><img src="assets/smoothStyle.png" className="styleIcon" /><div className="styleLabel">Smooth</div></button>
             <button className={"collection hover "  + (this.props.collection === "animated"? 'styleActive' : 'styleInactive')} onClick={() => this.props.toggleCollection('animated')}><img src="assets/heartbeat.gif" className="styleIcon" /><div className="styleLabel">Animated</div></button>
            </div>
            <div id="viewCount">
              Displaying {displayElementNumber}/{this.props.elementCount} objects
            </div>
          </div>
          {resultList} 
          <div id="loadmoreBox">
            <GeneralButton id="more" onClick={() => this.props.onLoadMore()} className={"button hover " + (this.props.loading ? 'hide' :(this.props.showingSearchResult ? 'hide':'show'))} >Load More</GeneralButton>
            <div id="noMoreAlert" className={'greenText ' + (this.props.allLoaded ? 'show' : 'hide')}>No more. We are adding new objects every week, come back later! 👨‍💻</div>
            <div id="noMoreAlert" className={'greyText ' + (this.props.noSearchResults ? 'show' : 'hide')}>Can't find any results for "{this.props.keyword}", you can <a href="https://isometriclove.nolt.io" target="_blank">request a new object here</a>.</div>
            {/*<div className="greyText viewCount"> {this.props.viewCount} total views</div> */}
          </div>
        </div>
        <div id="spinnerBox" className = {(this.props.loading ? 'show' : 'hide')} >
          <Spinner name='double-bounce' id='spinner' color="#82B97B" />
        </div>
      </React.Fragment>
    );
  }
}

export default Results;
