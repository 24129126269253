import React, { Component, useState } from 'react';
import {Elements} from 'react-stripe-elements';
import InjectedCheckoutForm from './checkoutForm';
import posed from 'react-pose';
import '@google/model-viewer';
import download from 'downloadjs';
import axios from "axios";
import BottomSliderTopNavi from "./bottomSliderTopNavi";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import ReactGA from 'react-ga';
import { loadStripe } from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_FIOqXHaIrqYB0RJArDqfYt6Y');

const CloseButton = posed.img({
  pressable: true,
  hoverable:true,
  init: {rotate:0, scale:1},
  press: {rotate:360, scale:2},
  hover: {rotate:360, scale:1.2}
});


const BottomSlider = posed.div({
  notVisible: {y:2000, opacity:1},
  visible: {y:0, opacity:1},
  transition: {
  duration: 1000,
  ease: 'linear'
}
});


const GeneralButton = posed.div({
  pressable: true,
  hoverable:true,
  init: {opacity:1, scale:1},
  press: {opacity:1, scale:1.2},
  hover: {opacity:0.5, scale:1.1}
});

// to control 3D model viewer size
var ModelViewerWidth = "90%";
var ModelViewerHeight = "90%";

//for 3D model sliders
const sliderStyle = {  // Give the slider some width
	position: 'relative',
	width: '100%',
	height: 80,
	border: 'none',
}

const railStyle = {
	position: 'absolute',
	width: '90%',
	height: 2,
	marginTop: 35,
	borderRadius: 5,
	backgroundColor: '#C8C8C8',
}

export function Handle({
	handle: { id, value, percent },
	getHandleProps
  }) {
	return (
	  <div
		style={{
		  left: `${percent}%`,
		  position: 'absolute',
		  marginLeft: -15,
		  marginTop: 25,
		  zIndex: 2,
		  width: 20,
		  height: 20,
		  border: 0,
		  textAlign: 'center',
		  cursor: 'pointer',
		  borderRadius: '50%',
		  backgroundColor: '#A6D3A0',
		  color: '#333',
		}}
		{...getHandleProps(id)}
	  >
		<div style={{fontSize: 11, marginTop: -25, color :'#A6D3A0' }}>
		  {value}
		</div>
	  </div>
	)
  }


  function Track({ source, target, getTrackProps }) {
	return (
	  <div
		style={{
		  position: 'absolute',
		  height: 2,
		  zIndex: 1,
		  marginTop: 35,
		  backgroundColor: '#A6D3A0',
		  borderRadius: 5,
		  cursor: 'pointer',
		  left: `${source.percent}%`,
		  width: `${target.percent - source.percent}%`,
		}}
		{...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */}
	  />
	)
  }
  
  

class Download extends Component {

	constructor(props){
		super(props);
		console.log("constructed");
		
		this.state = {
			visible: this.props.visible,
			selfRenderReady: false,
			element : this.props.element,
			bottomSlider:'download',   // 4 screens to show here - selfRender download, payment, thanks
			viewerExposure : 0.5,
			lighting : "studio_with_multiple_lights.hdr",
			cameraOrbit : "45deg 45deg 50m"
		}

	} //for slider

	componentDidMount(){
		document.getElementById('test').addEventListener('camera-change', function(){
			const cameraOrbit = test.getCameraOrbit();

			console.log(cameraOrbit);
			document.getElementById('cameraX').value = Math.round(cameraOrbit.theta*100) / 100; 
			document.getElementById('cameraY').value = Math.round(cameraOrbit.phi*100) / 100;
			document.getElementById('cameraDistance').value = Math.round(cameraOrbit.radius*100) / 100;
			
		});
	}

	componentDidUpdate(nextProps){

		if(this.props.visible !== this.state.visible){
				this.setState({visible: this.props.visible})
				console.log("fired 11");
		}

		if(this.props.element !== this.state.element){
			this.setState({element : this.props.element, bottomSlider : 'download'});
			this.checkElement3DReady(this.props.element);
			console.log("fired 22");
		}
	}

	// componentWillReceiveProps(nextProps) {

	// 	console.log("called");
	// 	Object.keys(nextProps)
	// 	  .filter(key => {
	// 		return nextProps[key] !== this.props[key];
	// 	  })
	// 	  .map(key => {
	// 		console.log(
	// 		  'changed property:',
	// 		  key,
	// 		  'from',
	// 		  this.props[key],
	// 		  'to',
	// 		  nextProps[key]
	// 		);
	// 	  });
	// }

	createPoster = async () => {
		test.style.width = "2000px";
		test.style.height = "2000px";
		setTimeout( async function(){ 
			var posterBlob = await test.toBlob();
			download(posterBlob, 'render.png', 'image/png');
			test.style.width = ModelViewerWidth;
			test.style.height = ModelViewerHeight;
	
		}, 50);

		await fetch('https://us-central1-isometriclove.cloudfunctions.net/elementDownloadCount?filename='+ this.state.element);
		ReactGA.event({
		  category: 'Download',
		  action: 'Self Render',
		  label : this.state.element
		});
	}

	tryRequire = (path) => {
		try {
		 return require(`${path}`);
		} catch (err) {

			console.log(err);
		 return null;
		}
	};

	handleBottomSliderPageChange = (page) => {
		this.setState({ bottomSlider: page});
		ReactGA.event({
			category: 'Bottom Slider',
			action: 'Bottom Slider Content Changed',
			label : page
		});
	}

	checkElement3DReady = async (element)=>{
		if (this.props.element !== ""){
			await axios.get('https://firebasestorage.googleapis.com/v0/b/isometriclove.appspot.com/o/model%2F'+this.props.element+'.glb?alt=media&token=5ef4cb72-39b1-491c-b0d6-261531cb210a').then((response) => {

			console.log(response.status);
				if (response.status === 200){
					this.setState({selfRenderReady : true});
				} else {
					this.setState({selfRenderReady : false});
				}
			}).catch((e)=>{
				console.log(e);
				this.setState({selfRenderReady : false});
			})
		}
	}


	exposureUpdate = ([value]) => {
		console.log(value);
		this.setState({viewerExposure : value});
	}

	lightingUpdate = (e) => {
		console.log(e.target.value);
		this.setState({lighting : e.target.value});
	}

	cameraOrbitUpdate = (e) => {
		this.setState({cameraOrbit : "10deg 10deg 100m"})
	}

	getReadyToClose = () => {
		
	}

	handlePayment = async (price) => {
		
		// // Get Stripe.js instance
		const stripe = await stripePromise;

		// Call your backend to create the Checkout Session
		const response = await fetch('https://us-central1-isometriclove.cloudfunctions.net/chargeCustomer?price='+price);
	
		const session = await response.json();
	
		// When the customer clicks on the button, redirect them to Checkout.
		const result = await stripe.redirectToCheckout({
		  sessionId: session.id,
		});
	
		if (result.error) {
		  // If `redirectToCheckout` fails due to a browser or network
		  // error, display the localized error message to your customer
		  // using `result.error.message`.
		  console.log(result.error.message);
		}
	}


  	render() {

		let imgURL = 'https://storage.googleapis.com/isometriclove.appspot.com/'+this.props.element;
		let modelURL = 'https://firebasestorage.googleapis.com/v0/b/isometriclove.appspot.com/o/model%2F'+this.props.element+'.glb?alt=media&token=5ef4cb72-39b1-491c-b0d6-261531cb210a';
		  
    	return (
	      	<BottomSlider id="bottomSlider" pose={this.state.visible ? 'visible' : 'notVisible'}>
			  
			  	<CloseButton src="assets/close.svg" id="closeButton" className="hover" onClick={this.props.onCloseDownload}/>
				
				  	{/* self render screen*/}

					<div className={'downloadScreen orangeText '+(this.state.bottomSlider === "selfRender" ? 'show':'hide')}>
						<BottomSliderTopNavi 
							selfRenderReady = {this.state.selfRenderReady}
							bottomSlider = {this.state.bottomSlider}
							onSliderPageChange = {this.handleBottomSliderPageChange}
						/>
						<div id="downloadPreviewBox">
							<model-viewer
								id="test"
								src={modelURL}
								environment-image={"/assets/environments/"+this.state.lighting}
								alt="viewer"
								ar
								preload
								auto-rotate
								camera-controls
								exposure={this.state.viewerExposure}
								// camera-orbit="45deg 45deg 100m"
								shadow-intensity="0.3"
								style={{ width: ModelViewerWidth, height: ModelViewerHeight, background: '#FBFBFB' }}
								quick-look-browsers="safari chrome"
							>
							</model-viewer>
							<div id="downloadDetails">
								Drag and zoom for desired angel.
							</div>
						</div>
						<div id="downloadRightBox">
							<div className = "smallerMarginTop downloadButtonBox" id="orbitValueBox">
								<div id="buttonDescription" className="greenText">Camera Obit</div>
								
								<input type="text" id="cameraX" value={this.state.cameraOrbit[0]} className="cameraOrbitText" readOnly/>
								<input type="text" id="cameraY" value={this.state.cameraOrbit[1]} className="cameraOrbitText" readOnly/>
								<input type="text" id="cameraDistance" value={this.state.cameraOrbit[2]} className="cameraOrbitText" readOnly/>
								
							</div>
							<div className = "smallerMarginTop downloadButtonBox">
								<div id="buttonDescription" className="greenText">Exposure</div>
								<Slider
									rootStyle={sliderStyle}
									domain={[0, 1]}
									step={0.001}
									mode={2}
									values={[this.state.viewerExposure]}
									onUpdate={this.exposureUpdate}
								>
									<Rail>
									{({ getRailProps }) => (
										<div style={railStyle} {...getRailProps()} />
									)}
									</Rail>
									<Handles>
									{({ handles, getHandleProps }) => (
										<div className="slider-handles">
										{handles.map(handle => (
											<Handle
											key={handle.id}
											handle={handle}
											getHandleProps={getHandleProps}
											/>
										))}
										</div>
									)}
									</Handles>
									<Tracks right={false}>
									{({ tracks, getTrackProps }) => (
										<div className="slider-tracks">
										{tracks.map(({ id, source, target }) => (
											<Track
											key={id}
											source={source}
											target={target}
											getTrackProps={getTrackProps}
											/>
										))}
										</div>
									)}
									</Tracks>
								</Slider>
							</div>
							<div className = "smallerMarginTop downloadButtonBox">
								<div id="buttonDescription" className="greenText">Environment Lighting</div>
								<select id="modelLightingDropDown" name="modelLighting" onChange={this.lightingUpdate}> 
									<option defaultChecked value="studio_with_multiple_lights.hdr">Studio with multiple lights</option> 
									<option value="studio_with_one_light.hdr">Studio with one light</option>	
									<option value="indoor_workshop.hdr">Indoor workshop</option>	
									<option value="outdoor.hdr">Outdoor</option>	
								</select>
							</div>
							<div className = "smallerMarginTop downloadButtonBox">
								{/*<a href={imgURL} target="_blank" download={this.props.element}> */}
								<GeneralButton className="downloadBtn hover greenButton" onClick={() => this.createPoster()}>Save as PNG</GeneralButton>
								<div id="buttonDescription" className="greenText">Free to use for non-commercial projects with an attribute link to Isometriclove.com</div>
							</div>
						</div>
					</div>



				  	{/* download existing image screen*/}
			
					<div className={'downloadScreen orangeText '+(this.state.bottomSlider === "download" ? 'show':'hide')}>

						<BottomSliderTopNavi 
							selfRenderReady = {this.state.selfRenderReady}
							bottomSlider = {this.state.bottomSlider}
							onSliderPageChange = {this.handleBottomSliderPageChange}
						/>

						<div id="downloadPreviewBox">
							<div id="downloadPreview">
								<img src={imgURL} id="previewImg" />
							</div>
							<div id="downloadDetails">
								{this.props.element}
							</div>
						</div>
						<div id="downloadRightBox">
							<div className = "downloadButtonBox">
								{/*<a href={imgURL} target="_blank" download={this.props.element}> */}
								<GeneralButton className="downloadBtn hover greenButton" onClick={this.props.onDownloadButtonClick}><img src="assets/download.svg" id="downloadIcon"/>Download</GeneralButton>
								<div id="buttonDescription" className="greenText">Free to use for non-commercial projects with an attribute link to Isometriclove.com</div>
							</div>

							<div className = "buyButtonBox">
					
								<GeneralButton className="downloadBtn smallFont hover orangeButton" onClick={()=>this.handlePayment(499)}>Get this image for a cup of coffee</GeneralButton>
						
								{/* <GeneralButton className="downloadBtn smallFont hover orangeButton" onClick={()=>this.handleBottomSliderPageChange("paymentSingle")}>Get this image for a cup of coffee</GeneralButton> */}
								<div id="buttonDescription" className="orangeText">Royalty-free license for this image at $4.99, good for commercial use, no need to attribute.</div>
							</div>

							<div className = "buyButtonBox">
								{/* <div id="licenceNum" className="orangeText">{this.props.licenceLeft} licences left</div> */}
								<GeneralButton className="downloadBtn smallFont hover orangeButton" onClick={()=>this.handlePayment(9900)}>Get all images on site for $99</GeneralButton>
								<div id="buttonDescription" className="orangeText">Royalty-free license for site-wise images, including future released images. No need to attribute.</div>
							</div>
						</div>
					</div>



					{/* single payment screen*/}

					<div className={'paymentScreen orangeText '+(this.state.bottomSlider === "paymentSingle" ? 'show':'hide')}>
						<GeneralButton className="backButton hover" onClick={()=>this.handleBottomSliderPageChange('download')}>
							<img src="/assets/back.svg" id="backArrow" />Back
						</GeneralButton>
						<div id="bigPrice">$4.99</div>
						<div id="paymentText">
							Buy me a cup of coffee and get the royalty-free license for this image. No need to attribute isometricLove.com.
							Payment handled by Stripe, we do not store any card information.
						</div>
						<img src="assets/stripe.png" id="stripLogo"/>
						<Elements>
							<InjectedCheckoutForm onGoBack={this.handleBottomSliderPageChange} fetchData={this.props.fetchData}  validateEmail={this.props.validateEmail} price='499'/>
						</Elements>
					</div>

					{/* all payment screen*/}

					<div className={'paymentScreen orangeText '+(this.state.bottomSlider === "paymentAll" ? 'show':'hide')}>
						<GeneralButton className="backButton hover" onClick={()=>this.handleBottomSliderPageChange('download')}>
							<img src="/assets/back.svg" id="backArrow" />Back
						</GeneralButton>
						<div id="bigPrice">$99</div>
						<div id="paymentText">
							For a one-off payment of $99, you will get the royalty-free license for the whole website, including all future releases. You can download as many images as you want for commercial projects. No need to attribute isometricLove.com. 
						</div>
						<img src="assets/stripe.png" id="stripLogo"/>
						<Elements>
							<InjectedCheckoutForm onGoBack={this.handleBottomSliderPageChange} fetchData={this.props.fetchData}  validateEmail={this.props.validateEmail} price='9900'/>
						</Elements>
					</div>



				{/* thanks screen*/}
				
				<div className={'paymentScreen orangeText '+(this.state.bottomSlider === "thanks" ? 'show':'hide')}>
					<img src="https://media0.giphy.com/media/l2R0eYcNq9rJUsVAA/giphy.gif" id="thankYouGif" /><br/>
					Your payment is successful, thanks for supporting Isometric Love. A confirmation has been sent to your email. <br/>
					Enjoy!<br/><br/> 
					<GeneralButton className="downloadBtn hover orangeButton" onClick={()=> this.handleBottomSliderPageChange('download')}>Back to Download</GeneralButton>
				</div>

	      	</BottomSlider>
    	);
  	}
}

export default Download;
