import React, { Component } from 'react'
import Results from '../results'
import Download from '../download'
import Popup from '../popup'
import posed from 'react-pose';
import { isMobile } from "react-device-detect";
import ReactGA from 'react-ga';
// import GET_SUBSCRIBERS_ENDPOINT from '../keys.js';

/* eslint-disable no-unused-expressions */

const RefreshButton = posed.img({
  pressable: true,
  hoverable:true,
  init: {rotate:0, scale:1},
  press: {rotate:360, scale:2},
  hover: {rotate:360, scale:1.2},
  beforeRotate: {rotate:0, scale:1},
  afterRotate: {rotate:180, scale:1},

});

const GeneralButton = posed.div({
  pressable: true,
  hoverable:true,
  init: {opacity:1, scale:1},
  press: {opacity:1, scale:1.2},
  hover: {opacity:0.5, scale:1.1}
});


var Spinner = require('react-spinkit')

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      downloadVisible: false, 
      element:"",
      elements:[],
      loading: false,
      elementCount: 0,
      viewCount:0,
      allLoaded: false,
      subscribeLoading: false,
      subscribeMsg:"",
      subscribeErr: false,
      licenceLeft:0,
      heroImages: [
        {"desktop" : "/assets/burgerHero.png", "mobile" : "/assets/burgerHeroMobile.png"},
        {"desktop" : "/assets/covidhero.png", "mobile" : "/assets/covidHeroMobile.png"},
        {"desktop" : "/assets/peachHero.png", "mobile" : "/assets/peachMobile.png", "url" : "https://peachplugin.com/"},
        {"desktop" : "/assets/smoothCollection.png", "mobile" : "/assets/smoothCollectionMobile.png"},
        // {"desktop" : "/assets/office.png", "mobile" : "/assets/officeMobile.png"},
        {"desktop" : "/assets/animatedHeader.gif", "mobile" : "/assets/carHeroHeaderMobile.gif"},
        // {"desktop" : "/assets/sushi.png", "mobile" : "/assets/sushiMobile.png"},
        // {"desktop" : "/assets/street.png", "mobile" : "/assets/streetMobile.png"},
        // {"desktop" : "/assets/coinmirror.png", "mobile" : "/assets/coinmirrorMobile.png"}
      ],
      heroImgRandomId: 99,
      refreshButtonRotate:false,
      elementAmountPerLoading:0,
      showingSearchResult: false,
      noSearchResults: false,
      keyword:"",
      collection: "all",
    }

    // this.validateEmail = this.this.validateEmail.bind(this);
  };




  componentWillMount(){
    this.randomHeroImg();
    if(isMobile){
      this.setState({elementAmountPerLoading:12});
    } else {
      this.setState({elementAmountPerLoading:25});
    }

    ReactGA.event({
      category: 'Hero Image Refreshed',
      action: 'Hero Image Refreshed'
    });
    
  }

  componentDidMount(){
    this.fetchData();

    setInterval(()=> {
      this.setState({refreshButtonRotate: !this.state.refreshButtonRotate});
      
      this.randomHeroImg();

    }, 4500);

    //initialize google analytics
    ReactGA.initialize('UA-125936548-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    
  }


  // fetch current data from storage
  fetchData = async() => {

    if (this.state.elementCount === this.state.elements.length && this.state.elementCount != 0) {
      this.setState({allLoaded: true});
    } else {

      this.setState({loading: true});
      console.log('fetching data start');

      var limitAmount = this.state.elements.length+this.state.elementAmountPerLoading;

      //fetch data from cloud function end point
      fetch('https://us-central1-isometriclove.cloudfunctions.net/getElements?limit='+limitAmount)
      .then(response => response.json())

      // save response into a json object 
      .then(parsedJSON => parsedJSON.map(element => ( 
      { imgName: `${element.name}`,})))                  

      //save json object to state
      .then(elements => this.setState({elements: elements, loading:false}))

      .catch(error => console.log('parsing failed', error))

      //get total element count from another function end point and save it to state
      fetch('https://us-central1-isometriclove.cloudfunctions.net/getStats')
      .then(response => response.json())
      .then(parsedJSON => this.setState({
        elementCount : parsedJSON.elementCount,
        viewCount : parsedJSON.viewCount,
        licenceLeft : parsedJSON.licenceLeft,
      }))
      .catch(error => console.log('parsing failed', error));

      console.log('fetching data finished');
    }
  }



  handleShowDownload = async (element) => {
    console.log("showing download");
    await this.setState({element:element, downloadVisible: true});
    ReactGA.event({
      category: 'Show Element',
      action: 'Click on element',
      label : element
    });
    console.log("state is updated to " +this.state.downloadVisible);
  };

  handleCloseDownload = async() => {
    console.log("handle close download");
    await this.setState({downloadVisible: false, element:null});

    //stop display spinner on element thumb
		var elementWithSpinner = document.getElementsByClassName('objectDarkerOverLay show');

		if (elementWithSpinner.length !== 0){
			elementWithSpinner[0].className = "hide";
    }
    
    console.log(this.state.downloadVisible);
  };

  validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


  handleSubscribe = (inputId) => {

    var email = document.getElementById(inputId).value;

    var validatedEmail = this.validateEmail(email);


    if (email != ""&& validatedEmail){
      this.setState({subscribeLoading: true});

      //add email to db
      fetch('https://us-central1-isometriclove.cloudfunctions.net/saveSubscriber?email='+email)
      .then(response => response.json())
      .then (parsedJSON => {

        console.log(parsedJSON.msg);

        if(parsedJSON.msg == "exist"){

          this.setState({
          subscribeLoading: false,
          subscribeMsg: 'You have already subscribed before :) 🖐️',
          subscribeErr: true
          })

        } else if(parsedJSON.msg == "added"){
          this.setState({
          subscribeLoading: false,
          subscribeMsg: 'Success! Keep an eye on your email in the future. ✉️',
          subscribeErr: false
          });

          ReactGA.event({
            category: 'Newsletter',
            action: 'Subscribed Successfully'
          });

        }
      })
      .catch(err => { 
          console.log('error saving subscribers'+ err);
          this.setState({
            subscribeMsg: "Errrr..something went wrong, please try again.",
            subscribeErr: true
          });
          ReactGA.event({
            category: 'Newsletter',
            action: 'Subscribed Unsuccessful'
          });
      })
    } else {
      this.setState({
        subscribeMsg: "Please enter a valide email.",
        subscribeErr: true
      });
    } 
  }


  clearMsg = () => {
    this.setState({
       subscribeMsg: ''
    })
  }


  // handleDownloadButtonClick = () => {
  //   fetch('https://us-central1-isometriclove.cloudfunctions.net/elementDownloadCount?filename='+ this.state.element)
  //   .then( 
  //     window.open('https://storage.googleapis.com/isometriclove.appspot.com/'+this.state.element, '_blank'))
  //   .catch(err => {'err updating download count'+err})
  // }



  handleDownloadButtonClick = async() => {

    try{
      window.open('https://storage.googleapis.com/isometriclove.appspot.com/'+this.state.element, '_blank');
      await fetch('https://us-central1-isometriclove.cloudfunctions.net/elementDownloadCount?filename='+ this.state.element);
      ReactGA.event({
        category: 'Download',
        action: 'Pre render',
        label : this.state.element
      });
      
    }catch(e){
      alert("something went wrong "+e);
    }
  }



  randomHeroImg = () => {
    var random;

    if(this.state.heroImgRandomId == 99){
      random = Math.floor(Math.random()*this.state.heroImages.length + 0);
    } else if(this.state.heroImgRandomId == this.state.heroImages.length-1) {
      random = 0;
    } else {
      random = this.state.heroImgRandomId + 1;
    }

    this.setState({heroImgRandomId: random, refreshButtonAnim: true})
  }


  //imgratingData to sendGrid
  migrate = () => {

    var http = require("https");

    var options = {
      "method": "POST",
      "hostname": "api.sendgrid.com",
      "port": null,
      "path": "/v3/contactdb/recipients",
      "headers": {
        "authorization": process.env.REACT_APP_SENDGRID_KEY,
        "content-type": "application/json"
      }
    };

    var chunks = [];

    fetch(process.env.REACT_APP_GET_SUBSCRIBERS_ENDPOINT)
    .then(response => response.json())
    .then(parsedJSON => parsedJSON.map(element => ( 
      { email: `${element.email}`})))
    .then(elements => {

      var req = http.request(options, function (res) {

      res.on("data", function (chunk) {
        chunks.push(chunk);
      });

      res.on("end", function () {
        var body = Buffer.concat(chunks);
        console.log(body.toString());
      });
      });  

      // req.write(JSON.stringify([ { email: 'example@example.com',
      //   first_name: 'Rick',
      //   last_name: 'User',
      //   age: 25 },
      // { email: 'example2@example.com',
      //   first_name: 'Example',
      //   last_name: 'User',
      //   age: 25 } ]));

      req.write(JSON.stringify(elements));
      req.end();

    })
  }

  handleSearch = async() => {

    var keyWord = document.getElementById('searchInput').value.toLowerCase();

    this.setState({
      keyword : keyWord,
      loading: true, 
      elements: [],
      showingSearchResult: true,
      allLoaded:false,
      noSearchResults: false
    });

    this.doSearch(keyWord);

    ReactGA.event({
      category: 'Search',
      action: 'User searched',
      label : keyWord
    });

  }

  doSearch = async(keyWord) => {

    let results

    if (this.state.collection === "all"){
      results = await fetch('https://us-central1-isometriclove.cloudfunctions.net/getElementsWithFilters2?keyword=' + keyWord);
    } else {
      results = await fetch('https://us-central1-isometriclove.cloudfunctions.net/getElementsWithFilters2?keyword=' + keyWord + '&collection='+ this.state.collection);
    }
    //fetch data from cloud function end point
    let jsonResults = await results.json();
    console.log(jsonResults);

    if (jsonResults.length === 0) {
      // console.log("nothing");
      this.setState({noSearchResults: true, loading: false});
      
    } else {
      // console.log("something");
      let elements = await jsonResults.map(element => ({ imgName: `${element.name}`,}));
      this.setState({elements: elements, loading:false, noSearchResults: false})
    }
    console.log('fetching data finished');

  }

  toggleCollection = async collection => {
    await this.setState({"collection": collection});
    this.handleSearch();
    ReactGA.event({
      category: 'Toggled Collection',
      action: 'Toggled collection',
      label : collection
    });
  }

  recordRequestClick = () => {
    ReactGA.event({
      category: 'Clicked Request Object',
      action: 'Clicked Request Object'
    });
  }


  render() {

    const pose = this.state.refreshButtonRotate ? 'beforeRotate' : 'afterRotate';

    return (
        <React.Fragment>
          <div className="stage">
            <Popup visible="true" 
              subscribeLoading={this.state.subscribeLoading}  
              clearMsg={this.clearMsg} 
              handleSubscribe={this.handleSubscribe}
              subscribeMsg = {this.state.subscribeMsg}
              subscribeErr = {this.state.subscribeErr}
            />
            <div id="hero">
              <div className="heading">Cute Isometric Objects<br/>For Your Design<br/></div>
              <div id="refreshBox">
                <RefreshButton pose={pose} onClick={() => this.randomHeroImg()} src="assets/refresh.svg" id="refreshButton" className="hover"/>
              </div>
              <div id="heroImgBox">
                 {this.state.heroImages[this.state.heroImgRandomId].url ? 
                  <React.Fragment>
                    <a href={this.state.heroImages[this.state.heroImgRandomId].url} target="_blank"><img src={this.state.heroImages[this.state.heroImgRandomId].desktop} id="heroImg" /></a>
                    <a href={this.state.heroImages[this.state.heroImgRandomId].url} target="_blank"><img src={this.state.heroImages[this.state.heroImgRandomId].mobile} id="heroImgMobile" /></a>
                  </React.Fragment>
                :
                  <React.Fragment>
                    <img src={this.state.heroImages[this.state.heroImgRandomId].desktop} id="heroImg" /> 
                    <img src={this.state.heroImages[this.state.heroImgRandomId].mobile} id="heroImgMobile" />
                  </React.Fragment>
                }
              </div>
            </div>
            <div id="searchBox"><img src="assets/magnifyGlass.svg" id="magnifyGlass" /><input type="text" id="searchInput" placeholder="Search for objects" onInput={() => this.clearMsg()} onKeyUp={() => this.handleSearch()} />
</div>
            <Results 
              onShowDownload={this.handleShowDownload} // function to show bottom slider
              onLoadMore={this.fetchData} 
              elements={this.state.elements} 
              loading={this.state.loading} 
              elementCount={this.state.elementCount} 
              viewCount={this.state.viewCount} 
              allLoaded={this.state.allLoaded}
              showingSearchResult={this.state.showingSearchResult}
              noSearchResults={this.state.noSearchResults}
              keyword={this.state.keyword}
              collection={this.state.collection}
              toggleCollection={this.toggleCollection}
            />
          </div>
          <div id="newsletter" className="crossWidthContainter">
            <img className="icon" src="assets/mail.png" />
            <div className="heading">Win Free Yearly Licence</div>
            2 yearly licences give away to new subscribers every month
            <div id="inputContainter">
              <input type="text" id="emailInput" className= "emailInput" placeholder="No spam, just nice objects." onInput={() => this.clearMsg()} />
              <GeneralButton id="submit" className={"button hover " + (this.state.subscribeLoading ? 'hide':'show')} onClick={() => this.handleSubscribe('emailInput')}>Subscribe</GeneralButton>
              <Spinner name='double-bounce' id="subscribeSpinner" color="#82B97B" className={(this.state.subscribeLoading ? 'show' : 'hide')}/>
              <div className={"inputMsg smallFont " + (this.state.subscribeErr ? 'redText' : 'whiteText')}>{this.state.subscribeMsg}</div>
            </div>
          </div>
          <div id="requestObject" className="crossWidthContainter">
            <img className="icon" src="assets/drone.png" />
            <div className="heading">Request a New Object</div>
            you can post a Peach Post below to request a new object, it's free but we can’t guarantee delivery time<br/><br/>you need <a href="http://peachplugin.com/" target="_blank">Peach Plugin</a> to view and post requests
          </div>
          <div className="stage">
            <Download 
              visible={this.state.downloadVisible} 
              onCloseDownload={this.handleCloseDownload} // function to close bottom slider
              element={this.state.element} 
              onDownloadButtonClick={this.handleDownloadButtonClick} 
              validateEmail={this.validateEmail}
              licenceLeft={this.state.licenceLeft}
              fetchData={this.fetchData}
            />
          </div>
          <a onClick={() => this.migrate()}>.</a>
        </React.Fragment>  
    );
  }
}

export default Home;
