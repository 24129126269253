import React, { Component } from 'react';
import './App.css';
import './Popup.css';
import Home from './components/pages/home';
import Test from './components/pages/test';
import Footer from './components/footer';
import Navi from './components/navi';
import {BrowserRouter, Route} from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import {StripeProvider} from 'react-stripe-elements';



class App extends Component {

  render() {
    return (
      <HttpsRedirect>
        <StripeProvider apiKey="pk_live_FIOqXHaIrqYB0RJArDqfYt6Y">
          <BrowserRouter>
            <div className="App">
                <Navi />
                  <Route path='/' exact component={Home} />
                  <Route path='/test' exact component={Test} />
                <Footer />
              </div>
          </BrowserRouter>
        </StripeProvider>
      </HttpsRedirect>
    );
  }
}

export default App;
