 // old version that works
import React from 'react';
import {injectStripe} from 'react-stripe-elements';
import CardSection from './cardSection';
import posed from 'react-pose';

var Spinner = require('react-spinkit');


const GeneralButton = posed.button({
  pressable: true,
  hoverable:true,
  init: {opacity:1, scale:1},
  press: {opacity:1, scale:1.2},
  hover: {opacity:0.5, scale:1.1}
});




class CheckoutForm extends React.Component {

	constructor(props){
		super(props);
		this.state={
			paymentProcessing:false,
			paymentErr:"",
			price: this.props.price
		}
		console.log(this.state.price);
	}

  	handleSubmit = (ev) => {
  		this.setState({paymentProcessing: true});
	    // We don't want to let default form submission happen here, which would refresh the page.
	    ev.preventDefault();
	    
	    var customerEmail = ev.target.paymentEmail.value;
	    var validatedEmail = this.props.validateEmail(customerEmail);	

 	    if (customerEmail != ""&& validatedEmail){

		    var customerTokenId;

		    // Within the context of `Elements`, this call to createToken knows which Element to
		    // tokenize, since there's only one in this group.
		    this.props.stripe.createToken({email: customerEmail })
		    .then(async({token}) => { 
		    	console.log(token.id);
		    	customerTokenId=token.id;
		    	fetch('https://us-central1-isometriclove.cloudfunctions.net/createCustomer?price='+this.state.price+'&email='+customerEmail+'&tok='+token.id);
		    })
		   .then(() => {
		   		this.props.fetchData();
		   		this.setState({paymentProcessing:false});
		   		this.props.onGoBack('thanks');
		   })
		   .catch(err => {
		   	console.log('err charging user'+customerEmail+' '+ err);
		   	this.setState({
		   		paymentErr: "Invalid card information.",
		   		paymentProcessing: false
		   	});
		   });
		} else {
			this.setState({
				paymentErr: "Invalid email address.",
				paymentProcessing:false
			});
		}
	   
	};

  render() {
    return (
      <form onSubmit={this.handleSubmit} id="paymentForm">
       	<CardSection />
       	<div id="inputLine2">
	       	<label><input type="text" id="paymentEmail" placeholder="Email" onInput={() => this.setState({paymentErr:""})}/></label>
	        <div id="paymentButtonBox">
	        	<GeneralButton type="submit" className={'button hover orangeButton '+(this.state.paymentProcessing ? 'hide' : 'show')} id="paymentSubmit">Confirm</GeneralButton>
	        	<Spinner name='double-bounce' id="paymentSpinner" color="#42C7AA" className={(this.state.paymentProcessing ? 'show' : 'hide')}/>
	        </div>
        </div>
        <div className={'err '+(this.state.paymentErr.length === 0 ? 'hide' : 'show')}>{this.state.paymentErr}</div>
      </form>
    );
  }
}

export default injectStripe(CheckoutForm);






