import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <div className="footer">
      	<img src="assets/bgate.png" id="bgate"/><br/>
      	All rights reserved 2020. Hand made in Berlin.<br/>
        heyisometriclove[at]gmail.com<br/><br/>
        <a href="https://peachplugin.com/?ref=ismlove" target="_blank"><img src="assets/peachBadgeRecBlk.png" width="200px"/></a><br/><br/>
      </div>
    );
  }
}

export default Footer;
