import React, { Component } from 'react';
import posed from 'react-pose';

const ClickableAd = posed.img({
  pressable: true,
  hoverable:true,
  init: {opacity:1, scale:1},
  press: {opacity:1, scale:1.05},
  hover: {opacity:0.8, scale:1.01}
});

class Adbox extends Component {
  render() {
    return (
    	<div id="ads">
        	<div><img src="/assets/1.png" className="ad"/></div>
        	<div><a href="https://isometriclove.nolt.io" target="_blank"><ClickableAd src="/assets/2.png" className="ad hover"/></a></div>
    	</div>
    );
  }
}

export default Adbox;
