import React from 'react';
import posed from 'react-pose';

class Showcase extends React.Component {

  render() {

  		const GeneralButton = posed.div({
  pressable: true,
  hoverable:true,
  init: {opacity:1, scale:1},
  press: {opacity:1, scale:1.2},
  hover: {opacity:0.5, scale:1.1}
});

    return (
      <div id="showcase" className="crossWidthContainter">
      <div className="heading topMargin">Showcase</div>
      	<div className="innerContainer">
    		<div className = "showcaseBox" id="showcase1">
    			 <img id="exchangrLogo" src="assets/exchangr.png" />
    			 <div id="buttonBox">
    			 	<GeneralButton id="ios" className="button hover showcaseButton" onClick={() => window.open('https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1450231744&mt=8')}>iOS</GeneralButton>
    			 	<GeneralButton id="andriod" className="button hover showcaseButton" onClick={() => window.open('https://play.google.com/store/apps/details?id=com.ricko.exchange')}>Android</GeneralButton>
    			 </div>
    		</div>
    		<div className = "showcaseBox" id="showcase2">
    			 <img id="coinmirrorLogo" src="assets/coinmirrorLogo.png" />
    			 <GeneralButton id="coinmirrorButton" className="button hover showcaseButton" onClick={() => window.open('https://www.coinmirror.co/')}>coinmirror.co</GeneralButton>
    		</div>
    	</div>
            
      </div>
   
    );
  }
}

export default Showcase;