import React, { Component } from 'react';
import posed from 'react-pose';

const CloseButton = posed.img({
  pressable: true,
  hoverable:true,
  init: {rotate:0, scale:1},
  press: {rotate:360, scale:2},
  hover: {rotate:360, scale:1.2}
});

const GeneralButton = posed.div({
  pressable: true,
  hoverable:true,
  init: {opacity:1, scale:1},
  press: {opacity:1, scale:1.2},
  hover: {opacity:0.5, scale:1.1}
});

var Spinner = require('react-spinkit')

class Popup extends Component {

	constructor(props){
		super(props);

		this.state = { 
      		popupvisible: false, 
  		}
	}

	componentDidMount(){
		// this.setState({popupvisible:true})

		setTimeout(() => { 
			this.setState({popupvisible:true})
		}, 5000);
	}


  render() {
    return (
    	<div className={this.state.popupvisible ? 'show' : 'hide'}>
	      	<div className="popup">
	      		<CloseButton src="assets/close.svg" id="closeButton" className="hover closePopup" onClick={() => this.setState({popupvisible:false})}/>
		      	<img src="/assets/mail.png" id="loveph" />
		      	<div className="heading white">Free Yearly Licences</div>
		      	<div className="white">2 yearly licences give away to new subscribers every month</div>
		      	<div id="inputContainter" className="inputContainerPopup">
	              <input type="text" id="emailInputPopup" className= "emailInput" placeholder="No spam, just nice objects." onInput={() => this.props.clearMsg()} />
	              <GeneralButton id="submit" className={"button hover submitOnPopup " + (this.props.subscribeLoading ? 'hide':'show')} onClick={() => this.props.handleSubscribe("emailInputPopup")}>Subscribe</GeneralButton>
	              <Spinner name='double-bounce' id="subscribeSpinner" color="#82B97B" className={(this.props.subscribeLoading ? 'show' : 'hide')}/>
	              <div className={"inputMsg smallFont " + (this.props.subscribeErr ? 'redText' : 'whiteText')}>{this.props.subscribeMsg}</div>
	            </div>
	        </div>

	        <div className="backdrop ">
	    	</div>
	        
        </div>
    );
  }
}

export default Popup;
