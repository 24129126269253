import React, { Component } from 'react'
import Results from '../results'
import Download from '../download'
import Popup from '../popup'
import posed from 'react-pose';
import { isMobile } from "react-device-detect";
import '@google/model-viewer';
import * as fs from 'fs';
import download from 'downloadjs';

var viewerWidth = "800px";
var viewerHeight = "800px";

class Test extends Component {


    createPoster = async () => {
        test.style.width = "2000px";
        test.style.height = "2000px";
        setTimeout( async function(){ 
            var posterBlob = await test.toBlob();
            download(posterBlob, 'render.png', 'image/png');
            test.style.width = viewerWidth;
            test.style.height = viewerHeight;

        }, 50);
    
    }


    render() {
        
        return(
            <React.Fragment>
                <div className="heading">Cute Isometric Objects<br/>For Your Design<br/></div>
                <div id="model">
                    <model-viewer
                        id="test"
                        src="/assets/models/deliveryRobot.glb"
                        // environment-image="/assets/environments/studio_small_02_16k.hdr"
                        alt="test"
                        ar
                        // auto-rotate
                        camera-controls
                        exposure="0.9"
                        // camera-orbit="45deg 45deg 50m"
                        shadow-intensity="0.3"
                        style={{ width: viewerWidth, height: viewerHeight, background: '#E4E4E4' }}
                        quick-look-browsers="safari chrome"
                    >
                    </model-viewer>
                    <button onClick={() => this.createPoster()}>Create Poster</button>
                </div>
            </React.Fragment>
        );
    }
}

export default Test;