// CardSection.js
import React from 'react';
import {CardElement} from 'react-stripe-elements';

class CardSection extends React.Component {
  render() {
    return (
      <label>
       <CardElement style={{
            base: {
                iconColor: '#42C7AA',
                color: '#5C5D67',
                lineHeight: '60px',
                fontWeight: 400,
                fontFamily: 'Styrene A, sans-serif',
                fontSize: '13px',

                '::placeholder': {
                    color: '#B5B5B5'
                }
            }
        }} />
      </label>
   
    );
  }
}

export default CardSection;